import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

class ImageBlock extends Component {
    render() {
        return (
            <div>
                <GatsbyImage image={getImage(this.props.data.imageXs)}

                    alt=""
                    title=""
                    className="img-fluid d-block d-sm-none"
                />
                <GatsbyImage image={getImage(this.props.data.imageXl)}

                    alt=""
                    title=""
                    className="img-fluid d-none d-sm-block"
                />
            </div>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                imageXs: file(relativePath: { eq: "CastAndCreativePage/pic-cast-576.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 575)
                    }
                }
                imageXl: file(relativePath: { eq: "CastAndCreativePage/pic-cast-1920.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 1920)
                    }
                }
            }
		`}
        render={data => (
            <ImageBlock data={data}/>
        )}
    />
)