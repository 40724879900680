import React from "react"
import Layout from "../../components/Layout/LayoutNewYork"
import PageHeader from "../../components/shared/PageHeader"
import CastBlock from "../../components/new-york/CastAndCreativePage/CastBlock"
import ImageBlock from "../../components/new-york/CastAndCreativePage/ImageBlock"
import CourthouseAnimation from "../../components/Animations/CourthouseAnimation";
import CreativeNewYork from "../../components/new-york/CastAndCreativePage/CreativeNewYork";
import { Helmet } from "react-helmet"

const CastPage = () => (
	<Layout
		title="Cast & Creative | New York | Back to the Future the MusicalF"
		description=" Adapting the iconic story of Back to the Future The Musical for the stage are the movie’s creators Bob Gale (Back to the Future trilogy) and Robert Zemeckis (Forrest Gump). Now playing on Broadway at the Winter Garden Theatre in New York."
		booking="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
	>
		<Helmet>
            <script
              id="satisfiScript"
              src="https://chat.satis.fi/popup/embedder?popupId=17903"
            ></script>
          </Helmet>
		<PageHeader city="new-york" title="Cast & Creative" />
		<CastBlock city="new-york"/>
		<ImageBlock/>
		<p className="text-center my-3">(London Cast)</p>
		<CourthouseAnimation/>
		<CreativeNewYork/>
	</Layout>
)

export default CastPage