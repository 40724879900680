import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby';
import CreativeCustomCollapse from "../CastAndCreativePage/CreativeCustomCollapse"
import styled from "styled-components";

const Content = styled.div`
    text-align: center;
`

class CreativeBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
        }
    }

    render() {
        return (
            <Content className="mb-3 mb-md-5">
                <h2 className="h1 mb-lg-4">Creative</h2>

                <Container>
                    <CreativeCustomCollapse data={this.props.data.allCreativesNewYorkJson.edges} type="creative" />
                </Container>
            </Content>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allCreativesNewYorkJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                        }
                    }
                }
               
            }`
        }
        render={data => (
            <CreativeBlock data={data}/>
        )}
    />
)