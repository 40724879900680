import React, {Component} from 'react';
import {Container, Row, Col} from 'reactstrap'
import {graphql, StaticQuery} from 'gatsby';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import CastCustomCollapse from "../CastAndCreativePage/CastCustomCollapse"
import styled from "styled-components";

const Content = styled.div`
    text-align: center;
`

class CastBlock extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
    }
  }
  
  render() {
    return (
      <Content className="my-3 my-md-5">
        <h2 className="h1 mb-lg-4">Cast</h2>
        
        {this.props.city !== "new-york" ?
          <Container>
            <CastCustomCollapse data={this.props.data.allMaincastJson.edges} type="maincast" />
            <CastCustomCollapse data={this.props.data.allCastJson.edges} type="cast" />
          </Container>
          :
          <Container>
            <CastCustomCollapse data={this.props.data.allTopRowCastNewYorkJson.edges} type="maincast" />
            <CastCustomCollapse data={this.props.data.allMainCastNewYorkJson.edges} type="maincast" />
          </Container>
          
        }
      
      </Content>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
            query {
               allMaincastJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                            image {
                                childImageSharp {
                                     gatsbyImageData(width: 400)
                                }
                            }
                        }
                    }
                }
              allMainCastNewYorkJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                            image {
                                childImageSharp {
                                     gatsbyImageData(width: 400)
                                }
                            }
                        }
                    }
                }
                allTopRowCastNewYorkJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                            image {
                                childImageSharp {
                                     gatsbyImageData(width: 400)
                                }
                            }
                        }
                    }
                }
                allCastJson {
                    edges {
                        node {
                            name
                            role
                            bioHTML
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 400)
                                }
                            }
                        }
                    }
                }
            }`
    }
    render={data => (
      <CastBlock data={data} {...props}/>
    )}
  />
)